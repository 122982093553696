import React from 'react'
import Img from 'gatsby-image'
import Lightbox from 'react-images';
import { last, reduce } from 'lodash';

export default class GalleryMasonry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shareOpen: false,
      anchorEl: null,
      lightbox: false,
      photos: props.images.map(photo => Object.assign({
        srcSet: photo.image.localFile.childImageSharp.fluid.srcSet,
        src: photo.image.localFile.childImageSharp.fluid.srcSet
       })),
    };
  }

  gotoPrevLightboxImage() {
    const { photo } = this.state;
    this.setState({ photo: photo - 1 });
  }

  gotoNextLightboxImage() {
    const { photo } = this.state;
    this.setState({ photo: photo + 1 });
  }

  openLightbox(photo, event) {
    event.preventDefault();
    this.setState({ lightbox: true, photo });
  }

  closeLightbox() {
    this.setState({ lightbox: false });
  }

  drawLargeTile(largeTile, images, rowIndex, type) {
    let imageIndex;
    if (type === 'left') {
      imageIndex = (rowIndex * 4);
    } else {
      imageIndex = (rowIndex * 4) + 3;
    }
    if (images[imageIndex]) {
      return (
        <a href={images[imageIndex].image.localFile.childImageSharp.fluid.src} onClick={e => this.openLightbox(imageIndex, e)}>
          <Img fixed={largeTile.image.localFile.childImageSharp.fixed} />
        </a>
      )
    }
  }

  drawGroupedImages(groupedImages, images, rowIndex, type) {
    return (
      <div className="columns is-multiline">
        {groupedImages.map((image, i) => {
          let imageIndex;
          if (type === 'left') {
            imageIndex = (rowIndex * 4) + i;
          } else {
            imageIndex = (rowIndex * 4) + i + 1;
          }
          if (i === 0) {
            return (
              <div className="column is-12" key={i}>
                <a href={images[imageIndex].image.localFile.childImageSharp.fluid.src} onClick={e => this.openLightbox(imageIndex, e)}>
                  <Img fixed={image.image.localFile.childImageSharp.fixed} />
                </a>
              </div>
            )
          } else {
            return (
              <div className="column is-6" key={i}>
                <a href={images[imageIndex].image.localFile.childImageSharp.fluid.src} onClick={e => this.openLightbox(imageIndex, e)}>
                  <Img fixed={image.image.localFile.childImageSharp.fixed} />
                </a>
              </div>
            )
          }
        })}
      </div>
    )
  }

  render() {
    const { thumbnails, images } = this.props;
    return (
      <div className="gallery-wrapper">
        {
          thumbnails.map((row, rowIndex) => {
            if (rowIndex % 2 === 0) {
              const largeTile = thumbnails[rowIndex][0];
              const otherTiles = reduce(thumbnails[rowIndex], (result, value, key) => {
                if (key > 0) {
                  result.push(value);
                }
                return result;
              }, []);

              return (
                <div className="columns" key={rowIndex}>
                  <div className="column is-6 large-tile">
                    {this.drawLargeTile(largeTile, images, rowIndex, 'left')}
                  </div>
                  <div className="column is-6 small-tiles">
                    {this.drawGroupedImages(otherTiles, images, rowIndex, 'right')}
                  </div>
                </div>
              )

            } else {
              const largeTile = last(thumbnails[rowIndex]);
              const otherTiles = reduce(thumbnails[rowIndex], (result, value, key) => {
                if (key <= 2) {
                  result.push(value);
                }
                return result;
              }, []);

              return (
                <div className="columns" key={rowIndex}>
                  <div className="column is-6 small-tiles">
                    {this.drawGroupedImages(otherTiles, images, rowIndex, 'left')}
                  </div>
                  <div className="column is-6 large-tile">
                    {this.drawLargeTile(largeTile, images, rowIndex, 'right')}
                  </div>
                </div>
              )
            }
          })
        }
        <Lightbox
          backdropClosesModal
          images={this.state.photos}
          currentImage={this.state.photo}
          isOpen={this.state.lightbox}
          onClickPrev={() => this.gotoPrevLightboxImage()}
          onClickNext={() => this.gotoNextLightboxImage()}
          onClose={() => this.closeLightbox()}
        />
      </div>
    );
  }
}
