import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import SEO from '../components/SEO'
import Layout from '../components/Layout'
import GalleryMasonry from '../components/GalleryMasonry'
import { chunk } from 'lodash'

export const PageTemplate = ({ title, content, thumbnails, images }) => {
  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <h2
              className="title is-size-3 has-text-weight-bold is-bold-light has-underline"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </div>
        </div>
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </div>
        </div>
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <GalleryMasonry thumbnails={thumbnails} images={images} />
          </div>
        </div>
      </div>
    </section>
  )
}

PageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
}

const Page = ({ data }) => {
  const { wordpressPage: page } = data
  const thumbnails = chunk(page.acf.gallery_thumbnails, 4);

  return (
    <Layout>
      <SEO
        title={page.title}
        description={(page.acf.meta_description ? page.acf.meta_description : null)}
      />
    <PageTemplate title={page.title} content={page.content} thumbnails={thumbnails} images={page.acf.gallery_images} />
    </Layout>
  )
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Page

export const pageQuery = graphql`
  query GalleryPageById($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      acf {
        meta_description
        gallery_thumbnails: gallery_items {
          image {
            localFile {
              childImageSharp {
                fixed(width: 500, height: 500) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        gallery_images: gallery_items {
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
